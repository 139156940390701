import React, { useEffect, useRef, useState } from "react";
import heroBg from "../assets/images/happyClinet.jpg";
import HeroImage from "../components/HeroImage";
import ContactUsForm from "../components/ContactUsForm";
import playIcon from "../assets/images/play.svg";
import "./HappyClinetPage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import DynamicSeo from "../utils/DynamicSeo.js";

const HappyClinetPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ----Hero Data ----

  const videos = [
    {
      highlightImage: require("../assets/images/highlightBox.png"),
      dummyVideo: require("../assets/videos/video.mp4"),
      playIcon: playIcon,
    },
    {
      highlightImage: require("../assets/images/highlightBox.png"),
      dummyVideo: require("../assets/videos/video.mp4"),
      playIcon: playIcon,
    },
    {
      highlightImage: require("../assets/images/highlightBox.png"),
      dummyVideo: require("../assets/videos/video.mp4"),
      playIcon: playIcon,
    },
    {
      highlightImage: require("../assets/images/highlightBox.png"),
      dummyVideo: require("../assets/videos/video.mp4"),
      playIcon: playIcon,
    },
    {
      highlightImage: require("../assets/images/highlightBox.png"),
      dummyVideo: require("../assets/videos/video.mp4"),
      playIcon: playIcon,
    },
    {
      highlightImage: require("../assets/images/highlightBox.png"),
      dummyVideo: require("../assets/videos/video.mp4"),
      playIcon: playIcon,
    },
  ];

  const [currentPlaying, setCurrentPlaying] = useState(null);

  // Refs for video elements
  const videoRefs = useRef(videos.map(() => React.createRef()));

  // Function to handle play/pause for individual videos
  const handlePlayPause = (index) => {
    if (currentPlaying === index) {
      // If this video is already playing, pause it
      videoRefs.current[index].current.pause();
      setCurrentPlaying(null);
    } else {
      // Pause the currently playing video, if any
      if (currentPlaying !== null && videoRefs.current[currentPlaying]) {
        videoRefs.current[currentPlaying].current.pause();
      }
      // Play the clicked video
      videoRefs.current[index].current.play();
      setCurrentPlaying(index);
    }
  };

  const headingOne = (
    <>
      Accelerate Your Brand Growth <span className="text-white">& Drive </span>
    </>
  );

  const headingTwo = "Sustainable Success";
  const description =
    "At MME, we meticulously design & develop digital experiences that deliver measurable success & leave a lasting impression.";

  const heroBtnsTxtOne = "Let’s Talk";

  const heroBtnsTxtTwo = "Set up a Free Consultation";

  return (
    <div>
      <DynamicSeo
        title="Happy Clients | Modern Media Expert"
        description="Discover success stories and testimonials from our satisfied clients. See how our services have made a difference for their businesses."
      />
      <HeroImage
        heroBtnsTxtOne={heroBtnsTxtOne}
        heroBtnsTxtTwo={heroBtnsTxtTwo}
        heroBg={heroBg}
        headingOne={headingOne}
        headingTwo={headingTwo}
        description={description}
      />

      <div className="happy-clients">
        <div className="container text-white py-5">
          <div className="text-center">
            <h1
              className="font-face-Montserrat-Bold"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              <span className="text-theme-light">Client</span> Testimonials
            </h1>
            <p
              className="font-face-Montserrat-Regular"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              We are committed to finding innovative and
              <span className="d-lg-block">unconventional solutions.</span>
            </p>
          </div>
          <div className="video-inner row justify-content-between">
            {videos?.map((video, index) => (
              <div
                key={index}
                className="video-main col-md-6 position-relative"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                <img
                  className="highlight-box img-fluid"
                  src={video.highlightImage}
                  alt="highlight-img"
                />

                <video
                  ref={videoRefs.current[index]}
                  className="video-style"
                  onClick={() => handlePlayPause(index)}
                >
                  <source src={video.dummyVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div
                  className="play-div"
                  onClick={() => handlePlayPause(index)}
                >
                  {currentPlaying !== index && (
                    <img
                      src={video.playIcon}
                      alt="play-icon"
                      className="play-icon"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <ContactUsForm />
      </div>
    </div>
  );
};

export default HappyClinetPage;
