import React, { useEffect } from "react";
import HeroImage from "../components/HeroImage";
import CustomServices from "../components/CustomServices";
import heroBg from "../assets/images/aboutHeroBg.jpg";
import Portfolio from "../components/Portfolio";
import Testimonial from "../components/Testimonial";
import ContactUsForm from "../components/ContactUsForm";
import ahmedahsanImg from "../assets/images/Testomonial Images/AboutPage/ahmedahsan.svg";
import ahmedalimansoorImg from "../assets/images/Testomonial Images/AboutPage/ahmedalimansoor.svg";
import raniaabdullahImg from "../assets/images/Testomonial Images/AboutPage/raniaabdullah.svg";
import DynamicSeo from "../utils/DynamicSeo.js";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ----Hero Data ----
  const headingOne = (
    <>
      We Redefine your <span className="text-white">Dreams</span>
    </>
  );
  const headingTwo = "into Successful Realities";
  const description =
    "Whether you're seeking captivating brand design, strategic digital marketing, or seamless web development, we tailored solutions accordingly.";

  // ----Custom Sertvices Data----
  const servicesHeadingOne = "What Is";
  const servicesHeadingTwo = "Modern Media Experts ";
  const serviceContent = (
    <>
      MME is a comprehensive hub for all your digital needs operating in the GCC
      <span className="d-lg-block">
        region, specializing in Branding, Designing, & Development.
      </span>
    </>
  );

  // -----Portfolio Data-----

  const servicesData = [
    {
      serviceImage: require("../assets/images/aboutService1.png"),
      serviceTitle: "Why Choose MME",
      serviceDescription:
        "We believe in going beyond the standard playbook to develop truly groundbreaking strategies that will help your brand flourish forthwith.",
    },

    {
      serviceImage: require("../assets/images/aboutService2.png"),
      serviceTitle: "Our Objective",
      serviceDescription:
        "At MME, we're all about crafting innovative solutions that get real results. We develop customized strategies tailored for your unique goals.",
    },

    {
      serviceImage: require("../assets/images/aboutService3.png"),
      serviceTitle: "Our Vision",
      serviceDescription:
        "Our bold vision is to build game changing digital experiences that drive measurable results for your business & help you forge a distinct brand identity.",
    },
    {
      serviceImage: require("../assets/images/aboutService4.png"),
      serviceTitle: "Our Strategy",
      serviceDescription:
        "Innovation is at the heart of everything we do at MME. We craft bespoke strategies that break the mold and exceed what you thought possible.",
    },
  ];

  const testimonialData = [
    {
      description:
        "MME has transformed our spa's online presence with a captivating brand identity and a website that reflects our tranquil atmosphere perfectly. Their SEO strategies have boosted our visibility across Bahrain. Their dedication to creativity is unmatched!",
      userName: "Ahmed Al Mansoor,",
      userRole: "Operations Manager, Falcon Logistics",
      icon: ahmedalimansoorImg,
    },
    {
      description:
        "Choosing Modern Media Expert was the best decision for our tech solutions firm. They designed a sleek logo and website that aligns perfectly with our innovative brand image. Their digital marketing campaigns have expanded our reach in the GCC market, generating leads and increasing our client base.",
      userName: "Ahmed Hasan,",
      userRole: "CTO, TechGulf Solutions",
      icon: ahmedahsanImg,
    },

    {
      description:
        "Modern Media Expert delivered exceptional results for our real estate agency. Their social media strategies have engaged our target audience, proving their expertise in digital marketing. We are impressed with their professionalism and strategic approach.",
      userName: "Rania Abdullah",
      userRole: "Marketing Manager, Dream Oasis Real Estate",
      icon: raniaabdullahImg,
    },
  ];

  const testimonialSubHeading =
    "Discover why our clients are our biggest advocates read their stories of success and partnership with us.";

  const heroBtnsTxtOne = "Get In Touch";

  const heroBtnsTxtTwo = "Free Consultation";

  const imageData = [
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/1.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/2.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/3.png"),
    },

    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/6.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/4.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/5.png"),
    },
  ];

  const BrandingImageData = [
    {
      src: require("../assets/images/Portfolio Images/Branding/1.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/2.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/3.png"),
    },

    {
      src: require("../assets/images/Portfolio Images/Branding/4.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/5.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/6.png"),
    },
  ];

  const WebsitesImageData = [
    {
      src: require("../assets/images/Portfolio Images/Websites/1.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/2.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/3.jpg"),
    },

    {
      src: require("../assets/images/Portfolio Images/Websites/4.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/5.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/6.jpg"),
    },
  ];
  return (
    <div>
      <DynamicSeo
        title="Modern Media Expert | Digital Agency for Exclusive Digital Services"
        description="At MME, we specialize in crafting distinct brand identities that help businesses thrive swiftly. Our services include logo design, web development, SEO, & SMM"
      />
      <HeroImage
        heroBtnsTxtOne={heroBtnsTxtOne}
        heroBtnsTxtTwo={heroBtnsTxtTwo}
        heroBg={heroBg}
        headingOne={headingOne}
        headingTwo={headingTwo}
        description={description}
      />
      <div className="about-page">
        <CustomServices
          servicesHeadingOne={servicesHeadingOne}
          servicesHeadingTwo={servicesHeadingTwo}
          serviceContent={serviceContent}
          servicesData={servicesData}
        />

        <Portfolio
          imageData={imageData}
          BrandingImageData={BrandingImageData}
          WebsitesImageData={WebsitesImageData}
        />
        <Testimonial
          testimonialData={testimonialData}
          testimonialSubHeading={testimonialSubHeading}
        />
        <ContactUsForm />
      </div>
    </div>
  );
};

export default AboutUsPage;
