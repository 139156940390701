import React, { useEffect } from "react";
import heroBg from "../assets/images/privacyPolicyBg.jpg";
import HeroImage from "../components/HeroImage";
import CustomPackages from "../components/CustomPackages";
import Portfolio from "../components/Portfolio";
import Testimonial from "../components/Testimonial";
import ContactUsForm from "../components/ContactUsForm";
import icon from "../assets/images/packagesIcon2.svg";
import MarketingServices from "../components/MarketingServices";
import Aos from "aos";
import "aos/dist/aos.css";
import DynamicSeo from "../utils/DynamicSeo.js";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ----Hero Data ----
  const headingOne = (
    <>
      Safeguarding Your Privacy with<span className="text-white">Our</span>
    </>
  );

  const headingTwo = "Trustworthy Practices";
  const description =
    "At MME, we prioritize your privacy, ensuring your data remains protected with our world-class design, marketing, and development services.";

  const privacyContent = [
    {
      heading: "Data Protection",
      description: (
        <>
          At Modern Media Expert, your data's security is our highest priority.
          We employ cutting-edge practices and technologies to protect your
          information. Our vigilant team continuously monitors and updates our
          systems to ensure your data is always safe.
        </>
      ),
    },

    {
      heading: "Privacy Assurance",
      description: (
        <>
          Security is paramount at Modern Media Expert. We employ
          industry-leading practices and technologies to safeguard your
          information. Our dedicated team monitors and updates our systems
          continuously to ensure your data remains secure and protected
        </>
      ),
    },

    {
      heading: "Enhanced Cookies",
      description: (
        <>
          We're committed to enhancing your browsing experience. Our website
          uses cookies to deliver personalized content and optimize
          functionality. These cookies help us understand site traffic and
          refine your overall experience.
        </>
      ),
    },

    {
      heading: "Refund Guidelines",
      description: (
        <>
          Your satisfaction is our commitment. If our services don't meet your
          expectations, you can request a refund within 30 days of purchase. We
          carefully assess each request and process approved refunds swiftly,
          ensuring a hassle-free experience.
        </>
      ),
    },

    {
      heading: "Simple Steps to Claim Your Refund",
      description: (
        <>
          Reach out to our dedicated support team within 30 days of your
          purchase or service. Whether by email, phone, or through our website’s
          support portal, this step initiates the refund process and addresses
          any concerns.
        </>
      ),

      description2: (
        <>
          Clearly explain your reasons for seeking a refund. Whether due to
          performance issues or unmet expectations, detailed explanations help
          us understand and improve.
        </>
      ),

      description3: (
        <>
          Our team will review your request against our criteria, ensuring
          fairness and transparency. We strive to process refunds promptly,
          respecting your time and trust.
        </>
      ),

      description4: (
        <>
          Once approved, your refund will be processed within 7-10 business
          days. We prioritize timely resolutions, making the process as smooth
          as possible.
        </>
      ),
      description5: (
        <>
          Throughout the refund process, you'll receive regular email updates,
          keeping you informed and assured every step of the way.
        </>
      ),
    },
  ];

  const heroBtnsTxtOne = "Let’s Connect";

  const heroBtnsTxtTwo = "Book a Consultation";

  return (
    <div>
      <DynamicSeo
        title="Privacy Policy - Modern Media Expert"
        description="Read the privacy policy we follow at MME. Learn how we protect your personal information and maintain data security via our digital services."
      />
      <HeroImage
        heroBtnsTxtOne={heroBtnsTxtOne}
        heroBtnsTxtTwo={heroBtnsTxtTwo}
        heroBg={heroBg}
        headingOne={headingOne}
        headingTwo={headingTwo}
        description={description}
      />

      <div className="privacy-policy">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              {privacyContent?.map((x, index) => {
                return (
                  <div
                    key={index}
                    className={`text-white ${
                      index !== privacyContent.length - 1 ? "mb-5" : ""
                    }`}
                  >
                    <h2
                      className="text-theme-light font-face-Montserrat-Bold"
                      data-aos="zoom-in"
                    >
                      {x.heading}
                    </h2>
                    <p
                      className="m-0 py-2"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      {x.description}
                    </p>

                    {x.description2 && (
                      <p
                        className="m-0 py-2 font-face-Montserrat-Regular"
                        data-aos="zoom-in"
                        data-aos-duration="2100"
                      >
                        {x.description2}
                      </p>
                    )}
                    {x.description3 && (
                      <p
                        className="m-0 py-2 font-face-Montserrat-Regular"
                        data-aos="zoom-in"
                        data-aos-duration="2200"
                      >
                        {x.description3}
                      </p>
                    )}

                    {x.description4 && (
                      <p
                        className="m-0 py-2 font-face-Montserrat-Regular"
                        data-aos="zoom-in"
                        data-aos-duration="2300"
                      >
                        {x.description4}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <ContactUsForm />
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
