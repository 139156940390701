import React, { useEffect } from "react";
import "./HeroImage.css";
import { useLocation } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroImage = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const location = useLocation();

  const openTawkToChat = () => {
    // Open TawkTo chat
    if (window.Tawk_API) {
      window.Tawk_API.toggle();
    }
  };

  const navigateToContactSection = () => {
    const element = document.getElementById("contactSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url(${props.heroBg})`,
        minHeight:
          location.pathname === "/terms-and-condition/" ||
          location.pathname === "/happy-clients/" ||
          location.pathname === "/privacy-policy/"
            ? "500px"
            : "",
        backgroundPosition:
          location.pathname === "/privacy-policy/" ? "right" : "",
      }}
      className="hero-main"
    >
      <div className="container">
        <div className="row hero-inner">
          <div className="col-xl-5 col-lg-6 col-md-8">
            <div className={location.pathname === "/" ? "hero-shadow" : ""}>
              <h1
                className="font-face-Montserrat-Bold hero-heading text-theme text-center text-xl-center text-lg-start text-md-start px-3"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                {props?.headingOne}
              </h1>

              <h1
                className="font-face-Montserrat-Bold hero-heading text-white text-center text-xl-center text-lg-start text-md-start px-3"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                {props?.headingTwo}
              </h1>
              <p
                className="font-face-Montserrat-Regular text-white text-center text-lg-start text-md-start px-3"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                {props?.description}
              </p>

              <div className="hero-btns">
                <button
                  className="hero-white-btn font-face-Montserrat-Medium"
                  onClick={openTawkToChat}
                >
                  {props?.heroBtnsTxtOne}
                </button>
                <button
                  className="hero-white-btn2 font-face-Montserrat-Medium"
                  onClick={navigateToContactSection}
                >
                  {props?.heroBtnsTxtTwo}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
