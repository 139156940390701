import React, { useEffect } from "react";
import heroBg from "../assets/images/marketingHeroBg.png";
import HeroImage from "../components/HeroImage";
import CustomPackages from "../components/CustomPackages";
import Portfolio from "../components/Portfolio";
import Testimonial from "../components/Testimonial";
import ContactUsForm from "../components/ContactUsForm";
import icon from "../assets/images/packagesIcon2.svg";
import MarketingServices from "../components/MarketingServices";
import aliMansoorImg from "../assets/images/Testomonial Images/MarketingPage/aliMansoor.svg";
import hauraHassanImg from "../assets/images/Testomonial Images/MarketingPage/hauraHassan.svg";
import khalifaImg from "../assets/images/Testomonial Images/MarketingPage/khalifa.svg";
import DynamicSeo from "../utils/DynamicSeo";

const DigitalMarketingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ----Hero Data ----
  const headingOne = (
    <>
      Fuel Your Business <span className="text-white">Success</span>
    </>
  );

  const headingTwo = "with Dynamic Digital Marketing";
  const description =
    "Our creative digital marketing strategies are designed to launch your products & services effortlessly, ensuring consistent growth.";

  // ---Branding Data---
  const brandingData = [
    {
      image: require("../assets/images/customBranding2.png"),
      title: "Logo Designing Service",
      description: (
        <>
          We are committed to finding innovative and unconventional solutions.
          Pushing boundaries.
          <span className="d-lg-block">to exceed client goals. </span>
        </>
      ),
    },
    {
      image: require("../assets/images/customBranding1.png"),
      title: "Branding Service",
      description: (
        <>
          We are committed to finding innovative and unconventional solutions.
          Pushing boundaries.
          <span className="d-lg-block">to exceed client goals. </span>
        </>
      ),
    },
  ];

  // -----Portfolio Data-----

  const packagesHeading = "Digital Marketing";
  const packagesHeadingTwo = "Packages";
  const packagesDescription = (
    <>
      From SEO to social media marketing, MME provides strategic, da
      <span className="d-lg-block">
        ta-driven solutions that deliver tangible results.
      </span>
    </>
  );

  const packagesData = [
    {
      title: "Starter Digital Boost Package",
      icon: icon,
      price: "149.000BD",
      features: [
        {
          title: "Social Media Kickstart",
          details: ["Launch your presence on major platforms."],
        },
        {
          title: "Basic SEO Optimization",
          details: ["Improve website visibility with essential optimizations."],
        },
        {
          title: "Introductory PPC Campaign",
          details: ["Targeted ads for initial visibility and leads."],
        },
        {
          title: "TikTok Introduction",
          details: ["Basic engagement strategies on TikTok."],
        },
        {
          title: "Basic Analytics",
          details: ["Monthly reports to track initial performance."],
        },
        {
          title: "Delivery Time",
          details: ["5-8 business days per service."],
        },
      ],
    },

    {
      title: "Advanced Digital Accelerator Package",
      icon: icon,
      price: "349.000BD",
      features: [
        {
          title: "Advanced Social Media Management",
          details: ["Comprehensive strategy across major platforms."],
        },
        {
          title: "Advanced PPC Campaigns",
          details: [
            "Targeted ads across multiple platforms for lead generation.",
          ],
        },
        {
          title: "TikTok Growth",
          details: ["Strategic content and engagement for exponential growth."],
        },

        {
          title: "Detailed Analytics",
          details: ["In-depth monthly reports with actionable insights."],
        },
        {
          title: "Delivery Time",
          details: ["10-15 business days per service."],
        },
      ],
    },

    {
      title: "Premium Digital Domination Package",
      icon: icon,
      price: "499.000BD",
      features: [
        {
          title: "Full-Scale Social Media Mastery",
          details: ["Complete management and growth strategies."],
        },
        {
          title: "Expert SEO Implementation",
          details: ["Advanced tactics for maximum organic traffic."],
        },
        {
          title: "Tailored PPC Mastery",
          details: ["Strategic ad campaigns across diverse platforms."],
        },

        {
          title: "TikTok Influencer Marketing",
          details: ["Harness TikTok's influence for viral campaigns."],
        },
        {
          title: "Comprehensive Analytics",
          details: ["Detailed reports with strategic recommendations."],
        },
        {
          title: "Delivery Time",
          details: [
            "Custom timelines tailored to each service, averaging 15-20 business days.",
          ],
        },
      ],
    },
  ];

  const testimonialData = [
    {
      description:
        "MME played a pivotal role in our digital growth journey. Their comprehensive digital marketing strategies, including PPC and content marketing, have driven substantial ROI. They are proactive, creative, and a pleasure to work with.",
      userName: "Ali Mansoor",
      userRole: "Head of Marketing, Silverline Technologies",
      icon: aliMansoorImg,
    },
    {
      description:
        "Modern Media Expert's digital marketing strategies have been instrumental in expanding our market presence. Their targeted campaigns and SEO tactics have significantly increased our website traffic and lead generation. Their expertise in digital marketing is unmatched.",
      userName: "Khalifa Mohammed,",
      userRole: "CEO, GlobalTech Solutions",
      icon: khalifaImg,
    },

    {
      description:
        "We engaged MME for digital marketing services, and their results have been outstanding. They tailored social media campaigns that resonated with our audience, boosting our brand visibility and customer engagement. Their strategic approach is commendable.",
      userName: "Noura Hassan",
      userRole: "Marketing Director, DreamCity Properties ",
      icon: hauraHassanImg,
    },
  ];
  const testimonialSubHeading =
    "Discover why our clients are our biggest advocates read their stories of success and partnership with us.";

  const heroBtnsTxtOne = "Let’s Collaborate";

  const heroBtnsTxtTwo = "Schedule a Free Session";
  return (
    <div>
      <DynamicSeo
        title="Modern Media Expert | Digital Agency for Innovative Digital Solutions"
        description="Wondering to Opt for efficient digital marketing services that assist you in scaling up your business? Reach us today at MME for all your digital needs"
      />
      <HeroImage
        heroBtnsTxtOne={heroBtnsTxtOne}
        heroBtnsTxtTwo={heroBtnsTxtTwo}
        heroBg={heroBg}
        headingOne={headingOne}
        headingTwo={headingTwo}
        description={description}
      />
      <div className="marketing-page">
        <MarketingServices />
        {/* <Portfolio imageData={imageData} /> */}

        <CustomPackages
          packagesHeading={packagesHeading}
          packagesHeadingTwo={packagesHeadingTwo}
          packagesDescription={packagesDescription}
          packagesData={packagesData}
        />
        <Testimonial
          testimonialData={testimonialData}
          testimonialSubHeading={testimonialSubHeading}
        />
        <ContactUsForm />
      </div>
    </div>
  );
};

export default DigitalMarketingPage;
