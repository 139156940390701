import React, { useEffect, useState } from "react";
import "./CustomPackages.css";
import arrow from "../assets/images/rightArrow.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import { Tab, Tabs } from "react-bootstrap";

const CustomPackages = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  // const navigateToContactSection = () => {
  //   const element = document.getElementById("contactSection");
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // };

  const openTawkToChat = () => {
    // Open TawkTo chat
    if (window.Tawk_API) {
      window.Tawk_API.toggle();
    }
  };

  const [key, setKey] = useState("logo");
  return (
    <div className="custom-packages">
      <div className="container py-5">
        <div className="text-center text-white">
          <h1
            className="text-white font-face-Montserrat-Bold"
            data-aos="zoom-in"
          >
            {props?.packagesHeading}{" "}
            <span className="text-theme-light" data-aos="zoom-in">
              {props?.packagesHeadingTwo}
            </span>{" "}
          </h1>
          <p
            className="font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="1900"
          >
            {props?.packagesDescription}
          </p>
        </div>

        <div>
          {/* <Tabs
            defaultActiveKey="logo"
            id="uncontrolled-tab-example"
            className="font-face-Montserrat-Bold"
          >
            <Tab eventKey="logo" title="Logo"> */}
          <div className="row pt-md-3 justify-content-md-center">
            {props?.packagesData?.map((packageItem, index) => (
              <div
                key={index}
                className={`col-lg-4 col-md-6 px-xl-3 ${
                  index !== props?.packagesData?.length - 1 ? "mb-5" : ""
                }`}
              >
                <div className="inner pt-4 pb-3 px-4 px-lg-4 px-md-4">
                  <div className="text-center">
                    <h4 className="text-white font-face-Montserrat-Medium">
                      {packageItem.title}
                    </h4>
                    <div className="py-2">
                      <img src={packageItem.icon} alt="package-icon" />
                    </div>
                    <h1 className="text-white font-face-Montserrat-Bold">
                      {packageItem.price}
                    </h1>
                  </div>

                  <div
                    className="package-scroll"
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      padding: "5px 0",
                    }}
                  >
                    {packageItem.features.map((feature, fIndex) => (
                      <div key={fIndex} className={fIndex === 0 ? "pt-3" : ""}>
                        <h6 className="text-theme-light font-face-Montserrat-Medium">
                          {feature.title}
                        </h6>
                        <ul className="text-white">
                          {feature.details.map((detail, dIndex) => (
                            <li key={dIndex}>
                              <small className="font-face-Montserrat-Regular">
                                {detail}
                              </small>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="package-btn font-face-Montserrat-Medium"
                      onClick={openTawkToChat}
                    >
                      Select Plan
                      <img
                        className="arrow-icon ms-2"
                        src={arrow}
                        alt="right-arrow"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* </Tab> */}
          {/* <Tab eventKey="devlopment" title="Web Developmnt">
              <div className="row pt-md-5 justify-content-md-center">
                {props?.packagesData?.map((packageItem, index) => (
                  <div
                    key={index}
                    className={`col-lg-4 col-md-6 px-xl-3 ${
                      index !== props?.packagesData?.length - 1 ? "mb-5" : ""
                    }`}
                  >
                    <div className="inner pt-4 pb-3 px-4 px-lg-4 px-md-4">
                      <div className="text-center">
                        <h4 className="text-white font-face-Montserrat-Medium">
                          {packageItem.title}
                        </h4>
                        <div className="py-2">
                          <img src={packageItem.icon} alt="package-icon" />
                        </div>
                        <h1 className="text-white font-face-Montserrat-Bold">
                          {packageItem.price}
                        </h1>
                      </div>

                      <div
                        className="package-scroll"
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                          padding: "5px 0",
                        }}
                      >
                        {packageItem.features.map((feature, fIndex) => (
                          <div
                            key={fIndex}
                            className={fIndex === 0 ? "pt-3" : ""}
                          >
                            <h6 className="text-theme-light font-face-Montserrat-Medium">
                              {feature.title}
                            </h6>
                            <ul className="text-white">
                              {feature.details.map((detail, dIndex) => (
                                <li key={dIndex}>
                                  <small className="font-face-Montserrat-Regular">
                                    {detail}
                                  </small>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>

                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="package-btn font-face-Montserrat-Medium"
                          onClick={openTawkToChat}
                        >
                          Select Plan
                          <img
                            className="arrow-icon ms-2"
                            src={arrow}
                            alt="right-arrow"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
            <Tab eventKey="marketing" title="Digitab Marketing">
              <div className="row pt-md-5 justify-content-md-center">
                {props?.packagesData?.map((packageItem, index) => (
                  <div
                    key={index}
                    className={`col-lg-4 col-md-6 px-xl-3 ${
                      index !== props?.packagesData?.length - 1 ? "mb-5" : ""
                    }`}
                  >
                    <div className="inner pt-4 pb-3 px-4 px-lg-4 px-md-4">
                      <div className="text-center">
                        <h4 className="text-white font-face-Montserrat-Medium">
                          {packageItem.title}
                        </h4>
                        <div className="py-2">
                          <img src={packageItem.icon} alt="package-icon" />
                        </div>
                        <h1 className="text-white font-face-Montserrat-Bold">
                          {packageItem.price}
                        </h1>
                      </div>

                      <div
                        className="package-scroll"
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                          padding: "5px 0",
                        }}
                      >
                        {packageItem.features.map((feature, fIndex) => (
                          <div
                            key={fIndex}
                            className={fIndex === 0 ? "pt-3" : ""}
                          >
                            <h6 className="text-theme-light font-face-Montserrat-Medium">
                              {feature.title}
                            </h6>
                            <ul className="text-white">
                              {feature.details.map((detail, dIndex) => (
                                <li key={dIndex}>
                                  <small className="font-face-Montserrat-Regular">
                                    {detail}
                                  </small>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>

                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="package-btn font-face-Montserrat-Medium"
                          onClick={openTawkToChat}
                        >
                          Select Plan
                          <img
                            className="arrow-icon ms-2"
                            src={arrow}
                            alt="right-arrow"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
          </Tabs> */}
        </div>
      </div>
    </div>
  );
};

export default CustomPackages;
