import React, { useEffect } from "react";
import thankImage from "../assets/images/thank.png";
import DynamicSeo from "../utils/DynamicSeo.js";

const ThankYouPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <DynamicSeo
        title="Digital Marketing Agency | Modern Media Expert"
        description="Thank you for choosing MME for branding, web development, and digital marketing services. Expect exceptional results and outstanding support from us"
      />

      <div className="thank-you-page">
        <div className="container py-5">
          <div className="text-center text-white">
            <img className="thank-img" src={thankImage} alt="thank-img" />

            <div className="mt-2">
              <p className="m-0">
                We are thrilled to have you on board - Thank you for choosing us
                as your Digital companion!
              </p>
            </div>

            <div className="mt-2">
              <p className="m-0">
                Your satisfaction is our utmost priority, and our commitment to
                excellence drives everything we do.
                <span className="d-lg-block">
                  Your trust in our services drives us to go beyond expectations
                  and deliver exceptional results
                </span>
              </p>
            </div>

            <div className="mt-3">
              <p className="m-0">
                If you have any questions or need assistance, please do not
                hesitate to reach out.
              </p>
            </div>

            <div className="mt-2">
              <p className="m-0">Warm Regards,</p>
            </div>

            <div className="mt-4">
              <h2 className="text-theme">MODERN MEDIA EXPERTS</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouPage;
