import React, { useEffect } from "react";
import heroBg from "../assets/images/termandconditionBg.jpg";
import HeroImage from "../components/HeroImage";
import CustomPackages from "../components/CustomPackages";
import Portfolio from "../components/Portfolio";
import Testimonial from "../components/Testimonial";
import ContactUsForm from "../components/ContactUsForm";
import icon from "../assets/images/packagesIcon2.svg";
import MarketingServices from "../components/MarketingServices";
import Aos from "aos";
import "aos/dist/aos.css";
import DynamicSeo from "../utils/DynamicSeo.js";

const TermsAndConditionPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ----Hero Data ----
  const headingOne = (
    <>
      Honest <span className="text-white">Collaboration,</span>
    </>
  );

  const headingTwo = "Strong Partnerships";
  const description =
    "MME establishes clear terms to earn our clients' trust and deliver exceptional design, marketing, and development services worldwide.";

  const privacyContent = [
    {
      heading: "Personal Information",
      description: (
        <>
          Your privacy is paramount to us. Any personal information collected
          during our services is treated with the utmost care and
          confidentiality. We ensure that your data is used solely for the
          purpose of delivering outstanding services and is never shared with
          unauthorized third parties.
        </>
      ),
    },

    {
      heading: "Security",
      description: (
        <>
          We employ advanced security measures to safeguard your data from
          unauthorized access, alteration, or destruction. Our team continuously
          updates security protocols to stay ahead of potential threats. This
          proactive approach ensures a safe digital environment for your
          business.
        </>
      ),
    },

    {
      heading: "Cookies",
      description: (
        <>
          We use cookies to enhance your experience on our website. These small
          data files help us understand your preferences. By continuing to use
          our site, you consent to our use of cookies in accordance with our
          privacy policy. You can manage your cookie preferences through your
          browser settings at any time.
        </>
      ),
    },

    {
      heading: "Refund Policy",
      description: (
        <>
          Customer satisfaction is paramount to us. If for any reason our
          services do not meet your expectations, we offer a straightforward
          refund policy to ensure your peace of mind. Your feedback is
          invaluable to us as we continuously strive to improve our services and
          exceed your expectations.
        </>
      ),
    },

    {
      heading: "How to Claim Your Refund",
      description: (
        <>
          At our prestigious digital marketing agency, we value your
          satisfaction above all else. We understand that sometimes, despite our
          best efforts, things may not always go as expected. If you find our
          services falling short of your standards, we encourage you to reach
          out to our dedicated support team within 30 days.
        </>
      ),

      description2: (
        <>
          Your feedback matters deeply to us—please share your reasons for
          seeking a refund and provide any relevant documentation that can help
          us better understand your experience. Rest assured, your refund
          request will receive careful consideration against our established
          refund policy.
        </>
      ),

      description3: (
        <>
          We believe in fairness and transparency, ensuring that every request
          is reviewed thoroughly. Upon approval, we strive to process your
          refund swiftly, typically within 7-10 business days. Throughout this
          process, we'll keep you informed with timely email updates, guiding
          you through each step.
        </>
      ),

      description4: (
        <>
          Your feedback helps us address immediate concerns & avoid any
          problematic situations ahead. Your satisfaction is at the heart of
          everything we do, and we're here to ensure your experience with us is
          nothing short of exceptional.
        </>
      ),
    },
  ];

  const heroBtnsTxtOne = "Let’s Connect";

  const heroBtnsTxtTwo = "Book a Consultation ";

  return (
    <div>
      <DynamicSeo
        title="Terms & Conditions | Modern Media Expert"
        description="Review the terms and conditions we follow at MME. Learn how we protect our customers’ data, & which practices we follow to protect their rights"
      />
      <HeroImage
        heroBtnsTxtOne={heroBtnsTxtOne}
        heroBtnsTxtTwo={heroBtnsTxtTwo}
        heroBg={heroBg}
        headingOne={headingOne}
        headingTwo={headingTwo}
        description={description}
      />
      <div className="terms-and-condition-page">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="text-center text-white">
                <h1 className="font-face-Montserrat-Bold" data-aos="zoom-in">
                  Our Pledge to{" "}
                  <span className="text-theme-light">Transparency </span>{" "}
                  <span className="d-lg-block text-theme-light">
                    and Security
                  </span>
                </h1>

                <p
                  className="font-face-Montserrat-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  At Modern Media Expert, we ensure that all our clients receive
                  a seamless experience. Our experts have the proficiency in
                  delivering exquisite services & solutions for Branding & Logo
                  Design, Web Development SEO, SMM, & Digital Marketing.
                </p>

                <p
                  className="mb-5 font-face-Montserrat-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="2100"
                >
                  We believe in building trust through transparency, ensuring
                  that our clients are always informed. With a deep
                  understanding of market trends and consumer behavior, we craft
                  compelling brand identities, visually striking logos, and
                  user-centric websites that resonate with your audience.
                </p>
              </div>

              {privacyContent?.map((x, index) => {
                return (
                  <div
                    key={index}
                    className={`text-white ${
                      index !== privacyContent.length - 1 ? "mb-5" : ""
                    }`}
                  >
                    <h2
                      className="text-theme-light font-face-Montserrat-Bold"
                      data-aos="zoom-in"
                      data-aos-duration="1900"
                    >
                      {x.heading}
                    </h2>
                    <p
                      className="m-0 py-2 font-face-Montserrat-Regular"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      {x.description}
                    </p>

                    {x.description2 && (
                      <p
                        className="m-0 py-2 font-face-Montserrat-Regular"
                        data-aos="zoom-in"
                        data-aos-duration="2100"
                      >
                        {x.description2}
                      </p>
                    )}
                    {x.description3 && (
                      <p
                        className="m-0 py-2 font-face-Montserrat-Regular"
                        data-aos="zoom-in"
                        data-aos-duration="2200"
                      >
                        {x.description3}
                      </p>
                    )}

                    {x.description4 && (
                      <p
                        className="m-0 py-2 font-face-Montserrat-Regular"
                        data-aos="zoom-in"
                        data-aos-duration="2300"
                      >
                        {x.description4}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <ContactUsForm />
      </div>
    </div>
  );
};

export default TermsAndConditionPage;
