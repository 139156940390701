import React, { useEffect } from "react";
import "./CustomServices.css";
import { useLocation, useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const CustomServices = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
  };
  return (
    <div className="custom-services">
      <div className="container py-5">
        <div className="text-center">
          <h1
            className="text-white font-face-Montserrat-Bold"
            data-aos="zoom-in"
          >
            {props?.servicesHeadingOne}{" "}
            <span className="text-theme-light" data-aos="zoom-in">
              {props?.servicesHeadingTwo}
            </span>
          </h1>
          <p
            className="text-white font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="1900"
          >
            {props?.serviceContent}
          </p>
        </div>

        {location?.pathname === "/about-us/" ? (
          <>
            <div className="row justify-content-center">
              {props.servicesData?.slice(0, 2).map((y, index) => (
                <div key={index} className="col-md-4 px-lg-4">
                  <div className="service-main mt-5" data-aos="flip-left">
                    <div className="service-inner">
                      <img
                        className="service-image img-fluid"
                        src={y.serviceImage}
                        alt="service-img"
                      />
                    </div>
                    <div
                      className={
                        y.arrowIcon ? "d-flex align-items-start pt-3" : "pt-3"
                      }
                    >
                      <h4 className="service-heading m-0 font-face-Montserrat-Bold">
                        {y.serviceTitle}
                      </h4>
                      {y.arrowIcon && (
                        <div className="service-circle ms-5 ms-lg-5 ms-md-0">
                          <img
                            className="tick-icon ms-5 ms-lg-5 ms-md-0"
                            src={y.arrowIcon}
                            alt="arrow-icon"
                          />
                        </div>
                      )}
                    </div>
                    <p className="m-0 service-description pt-3 font-face-Montserrat-Regular">
                      {y.serviceDescription}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="row justify-content-center">
              {props.servicesData?.slice(2, 4).map((y, index) => (
                <div key={index} className="col-md-4 px-lg-4">
                  <div className="service-main mt-5" data-aos="flip-right">
                    <div className="service-inner">
                      <img
                        className="service-image img-fluid"
                        src={y.serviceImage}
                        alt="service-img"
                      />
                    </div>
                    <div
                      className={
                        y.arrowIcon ? "d-flex align-items-start pt-3" : "pt-3"
                      }
                    >
                      <h4 className="service-heading m-0 font-face-Montserrat-Bold">
                        {y.serviceTitle}
                      </h4>
                      {y.arrowIcon && (
                        <div className="service-circle ms-5 ms-lg-5 ms-md-0">
                          <img
                            className="tick-icon ms-5 ms-lg-5 ms-md-0"
                            src={y.arrowIcon}
                            alt="arrow-icon"
                          />
                        </div>
                      )}
                    </div>
                    <p className="m-0 service-description pt-3 font-face-Montserrat-Regular">
                      {y.serviceDescription}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="row">
            {props?.servicesData?.map((y, index) => {
              return (
                <div
                  key={index}
                  className="col-md-4 px-lg-3"
                  onClick={() => handleNavigate(y.link)}
                >
                  <div className="service-main mt-5" data-aos="flip-left">
                    <div className="service-inner">
                      <img
                        className="service-image img-fluid"
                        src={y.serviceImage}
                        alt="service-img"
                      />
                    </div>
                    <div
                      className={
                        y.arrowIcon
                          ? "d-flex align-items-start pt-3 justify-content-between"
                          : "pt-3"
                      }
                    >
                      <h4 className="service-heading m-0 font-face-Montserrat-Bold">
                        {y.serviceTitle}
                      </h4>

                      {y.arrowIcon && (
                        <div className="service-circle">
                          <img
                            className="tick-icon"
                            src={y.arrowIcon}
                            alt="arrow-icon"
                          />
                        </div>
                      )}
                    </div>
                    <p className="m-0 service-description pt-3 font-face-Montserrat-Regular">
                      {y.serviceDescription}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomServices;
