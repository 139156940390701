import React, { useEffect } from "react";
import "./MarketingServices.css";
import arrowBlackIcon from "../assets/images/serciceArrowBlack.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const MarketingServices = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const servicesData = [
    {
      serviceImage: require("../assets/images/marketing1.png"),
      serviceTitle: "Social Media Marketing",
      serviceDescription: (
        <>
          Stand out on social media with our innovative strategies that go
          beyond traditional methods to achieve your growth goals.
        </>
      ),
    },

    {
      serviceImage: require("../assets/images/marketing3.png"),
      serviceTitle: "Search Engine Optimization",
      serviceDescription: (
        <>
          Increase your online visibility and attract high-quality traffic with
          our advanced SEO techniques, tailored for sustainable growth and
          success.
        </>
      ),
    },

    {
      serviceImage: require("../assets/images/marketing2.png"),
      serviceTitle: "Pay Per Click",
      serviceDescription: (
        <>
          Get the most out of your advertising budget with our targeted PPC
          campaigns, delivering measurable results and maximizing your
          <span className="d-lg-block">return on investment.</span>
        </>
      ),
    },

    {
      serviceImage: require("../assets/images/marketing4.png"),
      serviceTitle: "TikTok Marketing",
      serviceDescription: (
        <>
          Capture the attention of a global audience on TikTok with creative
          campaigns that resonate
          <span className="d-lg-block">and enhance your brand's presence.</span>
        </>
      ),
    },
  ];
  return (
    <div className="marketing-services">
      <div className="container-fluid py-5">
        <div className="text-center">
          <h1
            className="text-white font-face-Montserrat-Bold"
            data-aos="zoom-in"
            data-aos-duration="1900"
          >
            Expand Your Business Reach with{" "}
            <span className="text-theme-light">
              Our Digital Marketing Services
            </span>
          </h1>
          <p
            className="text-white font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="1900"
          >
            Boost your digital success with our expert strategies and creative
            solutions, tailored to amplify your
            <span className="d-lg-block">
              online presence & achieve remarkable growth.
            </span>
          </p>
        </div>

        <div className="row align-items-center justify-content-center pt-5">
          <div className="col-md-4 d-flex flex-column justify-content-between px-lg-4">
            {servicesData?.slice(0, 2).map((x, index) => (
              <div
                key={index}
                className={index === 1 ? "service-main mt-5" : "service-main"}
                data-aos="flip-left"
              >
                <div className="service-inner">
                  <img
                    className="service-image img-fluid"
                    src={x.serviceImage}
                    alt="service-img"
                  />
                </div>
                <h5 className="service-heading m-0 py-2 font-face-Montserrat-Bold">
                  {x.serviceTitle}
                </h5>
                <p className="m-0 service-description font-face-Montserrat-Regular">
                  {x.serviceDescription}
                </p>
              </div>
            ))}
          </div>

          <div className="col-md-4 px-lg-4 d-flex flex-column justify-content-center second-column">
            {servicesData?.slice(2).map((y, index) => (
              <div
                key={index}
                className="service-main mt-5"
                data-aos="flip-right"
              >
                <div className="service-inner">
                  <img
                    className="service-image img-fluid"
                    src={y.serviceImage}
                    alt="service-img"
                  />
                </div>
                <h5 className="service-heading m-0 py-2 font-face-Montserrat-Bold">
                  {y.serviceTitle}
                </h5>
                <p className="m-0 service-description font-face-Montserrat-Regular">
                  {y.serviceDescription}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingServices;
