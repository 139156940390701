import { Route, Routes, useLocation } from "react-router-dom";
import TawkTo from "tawkto-react";
import "./App.css";
import HomePage from "./pages/HomePage";
import Footer from "../src/layout/Footer";
import NavigationBar from "../src/layout/NavigationBar";
import AboutUsPage from "./pages/AboutUsPage";
import BrandingPage from "./pages/BrandingPage";
import WebDevelopmentPage from "./pages/WebDevelopmentPage";
import DigitalMarketingPage from "./pages/DigitalMarketingPage";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ThankYouPage from "./pages/ThankYouPage";
import HappyClinetPage from "./pages/HappyClinetPage";
import { useEffect, useState } from "react";
// import whatsappimg from "../src/assets/images/whatsapp.png";
import whatsappimg from "../src/assets/images/whatsappicon.svg";
import phoneimg from "../src/assets/images/phoneiconsvg.svg";
import PopupContactForm from "./components/PopupContactForm";

function App() {
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);

  const handleButton = () => {
    var url = "https://wa.me/+97336627909";
    window.open(url);
  };
  const navigateContact = () => {
    var url = "tel:+97336627909";
    window.location.href = url;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 50000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    var tawk = new TawkTo("668c37ff7a36f5aaec95d3b9", "1i29rlflb");

    tawk.onStatusChange((status) => {
      // handle status change if needed
    });
  }, []);

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: "280px",
          right: "2px",
          zIndex: "9999",
        }}
      >
        <img
          className="phone-icon"
          src={phoneimg}
          style={{
            cursor: "pointer",
          }}
          onClick={navigateContact}
        />
      </div>

      <div
        style={{
          position: "fixed",
          bottom: "222px",
          right: "2px",
          zIndex: "9999",
        }}
      >
        <img
          className="whatsApp-icon"
          src={whatsappimg}
          style={{
            cursor: "pointer",
          }}
          onClick={handleButton}
        />
      </div>

      {showForm ? <PopupContactForm setShowForm={setShowForm} /> : null}
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us/" element={<AboutUsPage />} />
        <Route path="/branding-and-design/" element={<BrandingPage />} />
        <Route
          path="/web-design-and-development/"
          element={<WebDevelopmentPage />}
        />

        <Route path="/marketing/" element={<DigitalMarketingPage />} />

        <Route
          path="/terms-and-condition/"
          element={<TermsAndConditionPage />}
        />

        <Route path="/privacy-policy/" element={<PrivacyPolicyPage />} />

        <Route path="/thank-you/" element={<ThankYouPage />} />

        <Route path="/happy-clients/" element={<HappyClinetPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
