import React, { useEffect, useState } from "react";
import "./ContactUsForm.css";
import LocationIcon from "../assets/images/locationIcon.svg";
import PhoneIcon from "../assets/images/phoneIcon.svg";
import ClockIcon from "../assets/images/clockIcon.svg";
import SupportIcon from "../assets/images/supportIcon.svg";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const ContactUsForm = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [notes, setNotes] = useState("");
  const [subject, setSubject] = useState("");

  const handleKeyDown = (e) => {
    // Allow only numeric input
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== " " &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const originalValue = e.target.value;
    const cleanedValue = originalValue.replace(/\D+/g, ""); // Remove non-digits
    setPhoneNumber(cleanedValue); // Update state with cleaned value
    e.target.value = cleanedValue; // Update input field display
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailInput = document.getElementById("email");
    const nameInput = document.getElementById("name");

    const phoneInput = document.getElementById("phone");
    const subjectInput = document.getElementById("subject");
    const messageInput = document.getElementById("message");

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (
      emailInput.value === "" ||
      nameInput.value === "" ||
      phoneInput.value === ""
    ) {
      alert("Please enter all required fields.");
      return;
    }

    if (!emailPattern.test(emailInput.value)) {
      alert("Please enter a valid email address.");
      return;
    }

    const response = await fetch(
      "https://backend.modernmediaexperts.com/api/email/V1/contactInfoMail",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userName: name,
          userEmail: email,
          userPhoneNumber: phoneNumber,
          userSubject: subject,
          userMessage: notes,
        }),
      }
    );
    const responseData = await response.json();

    console.log(responseData.msg, "check");
    document.getElementById("myForm").reset();

    navigate("/thank-you/");
  };

  const contacts = [
    {
      icon: LocationIcon,
      category: "Location",
      details: (
        <>
          Office 1633, Bldg 1565,Road 1722,
          <span className="d-md-block">Diplomatic Area, Manama 317, BH</span>
        </>
      ),
      link: "https://www.google.com/maps/place/The+Diplomat+Office+Towers/@26.2410494,50.5917559,3a,75y/data=!3m8!1e2!3m6!1sAF1QipNzKDawBb1tHPhE28KYfGzfdvd9nq6JUbwNRHn0!2e10!3e12!6s:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNzKDawBb1tHPhE28KYfGzfdvd9nq6JUbwNRHn0%3Dw86-h114-k-no!7i3024!8i4032!4m7!3m6!1s0x3e49a5ff4f1b27d5:0xd5b73518245ec48f!8m2!3d26.2410494!4d50.5917559!10e5!16s%2Fg%2F11j48hbnv9?entry=ttu",
    },
    {
      icon: PhoneIcon,
      category: "Contact Number",
      details: "+97336627909",
      link: "tel:+97336627909",
    },
    {
      icon: ClockIcon,
      category: "Hours",
      details: " Sunday to thursday 8:00-am to 6pm",
    },
    {
      icon: SupportIcon,
      category: "Email",
      details: "contact@modernmediaexperts.com",
      link: "mailto:contact@modernmediaexperts.com",
    },
  ];

  return (
    <div className="contact-main">
      <div className="container py-5">
        <div className="text-center">
          <h1
            className="text-white font-face-Montserrat-Bold"
            data-aos="zoom-in"
          >
            Contact Us
          </h1>

          <p
            className="text-white font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="1900"
          >
            We embrace unconventional approaches to surpass
            <span className="d-lg-block">
              client expectations & deliver outstanding results.
            </span>
          </p>
        </div>

        <div className="row pt-5">
          {contacts?.map((x, index) => {
            return (
              <div
                key={index}
                className={`col-md-3 ${
                  index !== contacts.length - 1
                    ? "border-right mb-5 mb-md-0 mb-lg-0"
                    : ""
                }`}
              >
                <div className="text-center" id="contactSection">
                  <a
                    href={x.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                    onClick={(e) => {
                      if (
                        x.link.startsWith("mailto:") ||
                        x.link.startsWith("tel:")
                      ) {
                        e.preventDefault(); // Prevent the default link behavior
                        window.location.href = x.link; // Manually handle the mailto link
                      }
                    }}
                  >
                    <img src={x.icon} alt="img" className="iconsHover" />

                    <p className="mt-3 text-theme-light font-face-Montserrat-Bold">
                      {x.category}
                    </p>
                    <small className="text-white font-face-Montserrat-Regular">
                      {x.details}
                    </small>
                  </a>
                </div>
              </div>
            );
          })}

          <div className="col-md-8 mx-auto mt-4">
            <form onSubmit={handleSubmit} id="myForm">
              <div className="input-views">
                <input
                  className="name-input font-face-Montserrat-Regular"
                  placeholder="Your Full Name"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  className="email-input font-face-Montserrat-Regular"
                  placeholder="Email Address"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input-views">
                <input
                  className="phone-input font-face-Montserrat-Regular"
                  placeholder="Phone"
                  id="phone"
                  // onChange={(e) => setPhoneNumber(e.target.value)}
                  // onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                />
                <input
                  className="subject-input font-face-Montserrat-Regular"
                  placeholder="Subject"
                  id="subject"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <textarea
                className="w-100 font-face-Montserrat-Regular"
                placeholder="Message"
                rows={4}
                id="message"
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>

              <div className="mt-3 tick-box">
                <button className="contact-btn mt-3 mt-md-0 mt-lg-0 font-face-Montserrat-Medium">
                  Let’s Connect
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
