import React from "react";
import "./Footer.css";
import logo from "../assets/images/Logo.svg";
import LinkdinIcon from "../assets/images/linkdinIcon.svg";
import InstaIcon from "../assets/images/instaIcon.svg";
import FbIcon from "../assets/images/fbIcon.svg";
import TiktokIcon from "../assets/images/tiktokIcon.svg";
import SitejabberIcon from "../assets/images/sitejabberIcon.svg";
import TrustPilotIcon from "../assets/images/trustPilotIcon.svg";
import BarkIcon from "../assets/images/barkIcon.svg";
import ReviewsIcon from "../assets/images/reviewsIcon.svg";
import arrowIcon from "../assets/images/arrow.svg";
import locIcon from "../assets/images/locIcon.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const socialLinks = [
    {
      icon: LinkdinIcon,
      links:
        "https://www.linkedin.com/company/modern-media-experts/about/?viewAsMember=true",
    },
    {
      icon: InstaIcon,
      links:
        "https://www.instagram.com/modernmediaexperts?igsh=MTg4YzlkbDUzNzRo&utm_source=qr",
    },
    {
      icon: FbIcon,
      links: "https://www.facebook.com/modernmediaexperts",
    },
    {
      icon: TiktokIcon,
      links: "https://www.tiktok.com/@modernmediaexperts?_t=8pKeoHVD7w1&_r=1",
    },
  ];

  const infoLinks = [
    {
      icon: SitejabberIcon,
      links: "https://www.sitejabber.com/reviews/modernmediaexperts.com",
    },
    {
      icon: TrustPilotIcon,
      links: "https://www.trustpilot.com/review/modernmediaexperts.com",
    },
    {
      icon: BarkIcon,
      links: "https://www.bark.com/en/us/company/modern-media--experts/RMeJv/",
    },
    {
      icon: ReviewsIcon,
      links:
        "https://www.reviews.io/company-reviews/store/modernmediaexperts.com",
    },
  ];

  const quickLinks = [
    {
      link: "About Us",
      linkTo: "/about-us/",
    },
    {
      link: "Branding & Logo Design",
      linkTo: "/branding-and-design/",
    },
    {
      link: "Web Design & Development",
      linkTo: "/web-design-and-development/",
    },
    {
      link: "Digital Marketing",
      linkTo: "/marketing/",
    },
    {
      link: "Contact Us",
      linkTo: console.log("clickk"),
    },
    {
      link: "Happy Client",
      linkTo: "/happy-clients/",
    },
  ];

  const supportedLinks = [
    {
      link: "Terms & Conditions",
      linkTo: "/terms-and-condition/",
    },
    {
      link: "Privacy Policy",
      linkTo: "/privacy-policy/",
    },
  ];

  const navigateTo = (path, title) => {
    if (title === "Contact Us") {
      const element = document.getElementById("contactSection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      console.log(element);
    } else {
      navigate(path);
      window.scrollTo(0, 0);
    }
  };

  const openNewTab = () => {
    window.open("https://fbaxperts.com/", "_blank", "noopener,noreferrer");
  };

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer-main pt-5 pb-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 text-center text-lg-start text-md-start">
            <img
              className="cursor-pointer"
              src={logo}
              alt="web-logo"
              onClick={() => handleNavigate("/")}
            />

            <div className="d-flex align-items-center mt-3 mt-lg-5 justify-content-center justify-content-lg-start justify-content-md-start">
              <span className="text-theme-dark font-face-Montserrat-Bold">
                Follow Us:
              </span>
              <div className="d-flex align-items-center">
                {socialLinks?.map((x, index) => {
                  return (
                    <div key={index}>
                      <a
                        href={x.links}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="ms-3 cursor-pointer">
                          <img src={x.icon} alt="icon-img" />
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex align-content-center mt-3 justify-content-center justify-content-lg-start justify-content-md-start">
              {infoLinks?.map((y, index) => {
                return (
                  <div
                    key={index}
                    className={
                      index !== 0 ? "ms-2 cursor-pointer" : "cursor-pointer"
                    }
                  >
                    <a href={y.links} target="_blank" rel="noopener noreferrer">
                      <div className="ms-3 cursor-pointer">
                        <img src={y.icon} alt="info-icons" />
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-0 mt-lg-0 text-white">
            <h5 className="text-theme-dark font-face-Montserrat-Bold">
              Quick Links
            </h5>
            {quickLinks?.map((y, index) => {
              return (
                <div
                  onClick={() => navigateTo(y.linkTo, y.link)}
                  key={index}
                  className="d-flex align-items-center py-1"
                >
                  <div>
                    <small className="text-theme-dark cursor-pointer font-face-Montserrat-Medium">
                      {y.link}
                    </small>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-0 mt-lg-0 text-white">
            <h5 className="text-theme-dark font-face-Montserrat-Bold">
              Support
            </h5>
            {supportedLinks?.map((z, index) => {
              return (
                <div
                  key={index}
                  onClick={() => navigateTo(z.linkTo)}
                  className="d-flex align-items-center py-1"
                >
                  <div>
                    <small className="text-theme-dark cursor-pointer font-face-Montserrat-Medium">
                      {z.link}
                    </small>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-5 mt-lg-0 subscribe text-center text-lg-start text-md-start">
            <h5 className="text-theme-dark font-face-Montserrat-Bold">
              Visit FBAXPERTS.COM
            </h5>
            <div onClick={openNewTab}>
              <button className="footer-btn font-face-Montserrat-Medium">
                Visit Now
              </button>
            </div>
            <div className="address-container mt-2">
              <div className="address-item">
                <img
                  src={locIcon}
                  alt="Location Icon"
                  className="location-icon"
                />
                <a
                  href="https://www.google.com/maps?q=633+Duval+St,Tallahassee,+FL+32399,+USA"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  <span className="font-face-Montserrat-Medium text-theme-light-white ms-2">
                    633 Duval St, Tallahassee, FL 32399, USA
                  </span>
                </a>
              </div>
              <div className="address-item">
                <img
                  src={locIcon}
                  alt="Location Icon"
                  className="location-icon"
                />
                <a
                  href=" https://www.google.com/maps/place/55+Powell+Ave,+Bethpage,+NY+11714,+USA/@40.7432624,-73.479143,17z/data=!3m1!4b1!4m6!3m5!1s0x89c28022d17f409f:0x30a3bf711902e088!8m2!3d40.7432584!4d-73.4765681!16s%2Fg%2F11c4w8dxw_?entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  <span className="font-face-Montserrat-Medium text-theme-light-white ms-2">
                    55 Powel Ave Bethpage, NY 11714
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr className="text-theme-dark" />

        <div className="text-center text-theme-dark">
          <small className="m-0 font-face-Montserrat-Medium">
            © 2024 All Rights Reserved{" "}
            <a
              href="https://modernmediaexperts.com"
              target="_blank"
              style={{ textDecoration: "none", color: "black" }}
            >
              modernmediaexperts.com
            </a>
          </small>
          <div className="m-0">
            <small className="m-0 font-face-Montserrat-Medium">
              All logos and trademarks featured on our website are the sole
              property of their respective owners. We emphasize that we have no
              affiliation, endorsement, or official connection with these
              companies or their trademarks. These logos and trademarks are
              displayed purely for identification purposes. The content provided
              on our website serves as general information and should not be
              considered professional advice. We strive for accuracy and
              completeness but do not guarantee it. We accept no liability for
              errors or omissions, nor for any outcomes resulting from the use
              of this information. Users are encouraged to exercise their own
              judgment when relying on the information provided.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
