import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./NavigationBar.css";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/images/Logo.svg";

const NavigationBar = () => {
  const [isNavbarFixed, setNavbarFixed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [activeLink, setActiveLink] = useState("/");
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const hideButtonOnRoutes = [
    "/marketing/",
    "/terms-and-condition/",
    "/privacy-policy/",
    "/happy-clients/",
    "/thank-you/",
    "/thank-you",
  ];
  const shouldHideButton = hideButtonOnRoutes.includes(currentPath);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleNavigate = (path) => {
    navigate(path);
    setExpanded(false);
    setOpenDropDown(false);
    setActiveLink(path);
    window.scrollTo(0, 0);
  };

  const handleToggle = () => {
    setExpanded(!expanded);
    if (expanded) {
      setOpenDropDown(false);
    }
  };

  const handleDropDownToggle = () => {
    setOpenDropDown(!openDropDown);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;

      if (scrollPosition > threshold) {
        setNavbarFixed(true);
      } else {
        setNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 990);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigateToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      setActiveLink(id);
    }
  };

  const navigateToSectionForPortfolio = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      setActiveLink(id);
    }
  };
  if (isMobileScreen) {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        onToggle={handleToggle}
        className={`bg-white ${isNavbarFixed ? "fixed-top" : ""}`}
      >
        <Container>
          <Navbar.Brand className="cursor-pointer">
            <div
              onClick={() => handleNavigate("/")}
              className="d-flex align-items-center justify-content-between"
            >
              <img src={Logo} alt="Logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                className="font-face-Montserrat-Medium"
                onClick={() => handleNavigate("/")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className="font-face-Montserrat-Medium"
                onClick={() => handleNavigate("/about-us/")}
              >
                About
              </Nav.Link>

              <NavDropdown
                className="font-face-Montserrat-Medium"
                title="Services"
                id="collapsible-nav-dropdown"
                show={openDropDown}
                onMouseEnter={() => setOpenDropDown(true)}
                onMouseLeave={() => setOpenDropDown(false)}
                onClick={handleDropDownToggle}
              >
                <NavDropdown.Item
                  onClick={() => handleNavigate("/branding-and-design/")}
                >
                  Branding And Logo Design Page
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() => handleNavigate("/web-design-and-development/")}
                >
                  Web Development and Design
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => handleNavigate("/marketing/")}>
                  Digital Marketing
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Nav className="pt-1 pb-3">
              <NavDropdown.Item
                className="font-face-Montserrat-Medium"
                onClick={() => handleNavigate("/happy-clients/")}
              >
                Happy Client
              </NavDropdown.Item>
            </Nav>

            {!shouldHideButton && (
              <Nav className="pt-1 pb-3">
                <NavDropdown.Item
                  className="font-face-Montserrat-Medium"
                  onClick={() =>
                    navigateToSectionForPortfolio("portfolioSection")
                  }
                >
                  Portfolio
                </NavDropdown.Item>
              </Nav>
            )}
            {!(
              location.pathname === "/thank-you" ||
              location.pathname === "/thank-you/"
            ) && (
              <Nav className="pt-1 pb-3">
                <NavDropdown.Item
                  className="font-face-Montserrat-Medium"
                  onClick={() => navigateToSection("contactSection")}
                >
                  Contact Us
                </NavDropdown.Item>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  const dropDownData = [
    {
      name: "Branding",
      src: require("../assets/images/dropdown1.png"),
      route: "/branding-and-design/",
      items: ["Logo Design", "Branding Solutions"],
    },
    {
      name: "Design & Development",
      src: require("../assets/images/dropdown2.png"),
      route: "/web-design-and-development/",
      items: [
        "Web Design UI/UX",
        "Custom Development",
        "Wordpress Development",
      ],
    },
    {
      name: "Digital Marketing",
      src: require("../assets/images/dropdown3.png"),
      route: "/marketing/",
      items: [
        "Social Media Marketing",
        "Pay Per Click",
        "Search Engine Optimization",
        "TikTok Marketing",
      ],
    },
  ];

  const dropDownMain = () => {
    return (
      <div className="custom-dropdown">
        <div className="container mx-auto">
          <div className="row mx-auto">
            {dropDownData?.map((dropDown, index) => {
              return (
                <div key={index} className="col-md-4 mb-2 text-white">
                  <div
                    onClick={() => handleNavigate(dropDown.route)}
                    className="p-3 cursor-pointer"
                  >
                    <img className="img-fluid" src={dropDown.src} alt="1" />

                    <h4
                      className={`font-face-Montserrat-Medium ${
                        activeLink === dropDown.route
                          ? "nav-link-active pt-3"
                          : "pt-3"
                      }`}
                    >
                      {dropDown.name}
                    </h4>

                    <ul className="text-white">
                      {dropDown?.items?.map((item, i) => (
                        <li
                          className="pt-2 font-face-Montserrat-Regular"
                          key={i}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        onToggle={handleToggle}
        className={`bg-white ${isNavbarFixed ? "fixed-top" : ""}`}
      >
        <Container>
          <Navbar.Brand
            onClick={() => handleNavigate("/")}
            className="cursor-pointer"
          >
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                onClick={() => handleNavigate("/")}
                className={`font-face-Montserrat-Medium ${
                  activeLink === "/" ? "nav-link-active" : ""
                }`}
              >
                Home
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavigate("/about-us/")}
                className={`font-face-Montserrat-Medium ${
                  activeLink === "/about-us/" ? "nav-link-active" : ""
                }`}
              >
                About
              </Nav.Link>

              <NavDropdown
                title="Services"
                id="collapsible-nav-dropdown"
                show={openDropDown}
                // onMouseEnter={() => setOpenDropDown(true)}
                // onMouseLeave={() => setOpenDropDown(false)}
                onClick={handleDropDownToggle}
              >
                {dropDownMain()}
              </NavDropdown>

              <Nav.Link
                onClick={() => handleNavigate("/happy-clients/")}
                className={`font-face-Montserrat-Medium ${
                  activeLink === "/happy-clients/" ? "nav-link-active" : ""
                }`}
              >
                Happy Client
              </Nav.Link>

              {!shouldHideButton && (
                <Nav.Link
                  className={`font-face-Montserrat-Medium ${
                    activeLink === "portfolioSection" ? "nav-link-active" : ""
                  }`}
                  onClick={() =>
                    navigateToSectionForPortfolio("portfolioSection")
                  }
                >
                  Portfolio
                </Nav.Link>
              )}
              {!(
                location.pathname === "/thank-you" ||
                location.pathname === "/thank-you/"
              ) && (
                <Nav.Link
                  className={`font-face-Montserrat-Medium ${
                    activeLink === "contactSection" ? "nav-link-active" : ""
                  }`}
                  onClick={() => navigateToSection("contactSection")}
                >
                  Contact Us
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
