import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Portfolio.css";
import { useLocation } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { Modal } from "react-bootstrap";
import CloseIcon from "../assets/images/modelClose.png";
import nextIcon from "../assets/images/next.png";
import previousIcon from "../assets/images/previous.png";

const Portfolio = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const location = useLocation();
  const [key, setKey] = useState("home");
  let shower = [];

  const image = props?.imageData;

  const BrandingImage = props?.BrandingImageData;
  const WebsitesImage = props?.WebsitesImageData;

  if (key == "contact") {
    shower = props?.WebsitesImageData;
  } else if (key == "profile") {
    shower = props?.BrandingImageData;
  } else {
    shower = image;
  }

  const [show, setShow] = useState(false);
  const [modalImg, setModalImg] = useState("");
  const [currentIndex, setCurrentIndex] = useState();

  const handleShow = (imgSrc, index) => {
    setCurrentIndex(index);

    setModalImg(imgSrc);
    setShow(true);
  };

  const handleNext = (index) => {
    if (index + 1 > 5) {
      setCurrentIndex(0);
      setModalImg(shower[0].src);
      setShow(true);
    } else {
      setCurrentIndex(index + 1);
      setModalImg(shower[index + 1].src);
      setShow(true);
    }
  };

  const handlePrevious = (index) => {
    if (index - 1 < 0) {
      setCurrentIndex(5);
      setModalImg(shower[5].src);
      setShow(true);
    } else {
      setCurrentIndex(index - 1);
      setModalImg(shower[index - 1].src);
      setShow(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleTab = () => {
    console.log("hhhh");
  };

  useEffect(() => {
    if (location.pathname === "/web-design-and-development/") {
      setKey("contact");
    }
  }, [location.pathname]);
  return (
    <>
      <div className="portfolio-main" id="portfolioSection">
        <div className="container">
          {location.pathname === "/branding-and-design/" && (
            <div className="text-center text-white">
              <h1 className="font-face-Montserrat-Bold" data-aos="zoom-in">
                <span className="text-theme-light">Our Spectacular </span>{" "}
                Portfolio
              </h1>
              <p
                className="m-0 font-face-Montserrat-Regular"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                Discover our innovative projects that showcase our
                collaborations with clients to
                <span className="d-lg-block">
                  achieve their digital aspirations and enhance their online
                  presence.
                </span>
              </p>
            </div>
          )}
          {location.pathname === "/web-design-and-development/" && (
            <div className="text-center text-white">
              <h1 className="font-face-Montserrat-Bold">
                <span className="text-theme-light" data-aos="zoom-in">
                  Our Impactful
                </span>{" "}
                Portfolio
              </h1>
              <p
                className="m-0 font-face-Montserrat-Regular"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                Explore our groundbreaking projects highlighting our client
                partnerships focused on
                <span className="d-lg-block">
                  achieving their digital aspirations & boosting their online
                  footprint.
                </span>
              </p>
            </div>
          )}
          {location.pathname === "/marketing/" && (
            <div className="text-center text-white">
              <h1 className="font-face-Montserrat-Bold">
                <span className="text-theme-light" data-aos="zoom-in">
                  Our Prestigious
                </span>{" "}
                Portfolio
              </h1>
              <p
                className="m-0 font-face-Montserrat-Regular"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                Delve into our innovative projects that exemplify our
                collaborations with clients to meet
                <span className="d-lg-block">
                  their digital objectives and enhance their online impact.
                </span>
              </p>
            </div>
          )}
          {location.pathname === "/" && (
            <div className="text-center text-white">
              <h1 className="font-face-Montserrat-Bold" data-aos="zoom-in">
                <span className="text-theme-light">Portfolio </span> Showcase
              </h1>
              <p
                className="m-0 font-face-Montserrat-Regular"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                We are pioneers in the design and development industry.
                <span className="d-lg-block">
                  Discover our wide range of splendid projects.
                </span>
              </p>
            </div>
          )}
          {location.pathname === "/about-us/" && (
            <div className="text-center text-white">
              <h1 className="font-face-Montserrat-Bold" data-aos="zoom-in">
                <span className="text-theme-light">Portfolio</span> Showcase
              </h1>
              <p
                className="m-0 font-face-Montserrat-Regular"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                Explore our disruptive projects that illustrate our partnerships
                with clients to achieve
                <span className="d-lg-block">
                  their digital goals & amplify their online presence.
                </span>
              </p>
            </div>
          )}
        </div>

        <div className="py-5">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 font-face-Montserrat-Bold"
            onClick={() => handleTab()}
          >
            {location.pathname !== "/web-design-and-development/" && (
              <Tab eventKey="home" title="Logofolio">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                      <img
                        src={image[0].src}
                        alt="Description1"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(image[0].src, 0)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0 d-flex justify-content-center">
                      <img
                        src={image[1].src}
                        alt="Description2"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(image[1].src, 1)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0 d-flex justify-content-center">
                      <img
                        src={image[2].src}
                        alt="Description1"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(image[2].src, 2)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                      <img
                        src={image[3].src}
                        alt="Description2"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(image[3].src, 3)}
                        style={{ cursor: "pointer" }}
                      />

                      <div className="row mx-auto">
                        <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                          <img
                            src={image[4].src}
                            alt="Description1"
                            className="w-100 img-fluid"
                            onClick={() => handleShow(image[4].src, 4)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                          <img
                            src={image[5].src}
                            alt="Description2"
                            className="w-100 img-fluid"
                            onClick={() => handleShow(image[5].src, 5)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            )}
            {location.pathname !== "/web-design-and-development/" && (
              <Tab eventKey="profile" title="Branding">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                      <img
                        src={BrandingImage[0].src}
                        alt="Description1"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(BrandingImage[0].src, 0)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0 d-flex justify-content-center">
                      <img
                        src={BrandingImage[1].src}
                        alt="Description2"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(BrandingImage[1].src, 1)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0 d-flex justify-content-center">
                      <img
                        src={BrandingImage[2].src}
                        alt="Description1"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(BrandingImage[2].src, 2)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                      <img
                        src={BrandingImage[3].src}
                        alt="Description2"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(BrandingImage[3].src, 3)}
                        style={{ cursor: "pointer" }}
                      />

                      <div className="row mx-auto">
                        <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                          <img
                            src={BrandingImage[4].src}
                            alt="Description1"
                            className="w-100 img-fluid"
                            onClick={() => handleShow(BrandingImage[4].src, 4)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                          <img
                            src={BrandingImage[5].src}
                            alt="Description2"
                            className="w-100 img-fluid"
                            onClick={() => handleShow(BrandingImage[5].src, 5)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            )}
            {location.pathname !== "/branding-and-design/" && (
              <Tab eventKey="contact" title="Webfolio">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                      <img
                        src={WebsitesImage[0].src}
                        alt="Description1"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(WebsitesImage[0].src, 0)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0 d-flex justify-content-center">
                      <img
                        src={WebsitesImage[1].src}
                        alt="Description2"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(WebsitesImage[1].src, 1)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0 d-flex justify-content-center">
                      <img
                        src={WebsitesImage[2].src}
                        alt="Description1"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(WebsitesImage[2].src, 2)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                      <img
                        src={WebsitesImage[3].src}
                        alt="Description2"
                        className="w-100 img-fluid"
                        onClick={() => handleShow(WebsitesImage[3].src, 3)}
                        style={{ cursor: "pointer" }}
                      />

                      <div className="row mx-auto">
                        <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                          <img
                            src={WebsitesImage[4].src}
                            alt="Description1"
                            className="w-100 img-fluid"
                            onClick={() => handleShow(WebsitesImage[4].src, 4)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div className="col-md-6 p-2 p-lg-0 p-md-0 p-0">
                          <img
                            src={WebsitesImage[5].src}
                            alt="Description2"
                            className="w-100 img-fluid"
                            onClick={() => handleShow(WebsitesImage[5].src, 5)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            )}
          </Tabs>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <div
          onClick={handleClose}
          className="text-end modal-close cursor-pointer pb-3"
        >
          <img src={CloseIcon} alt="close-icon" />
        </div>
        <Modal.Body>
          {/* <button onClick={() => handlePrevious(currentIndex)}>Previous</button> */}
          <img
            src={previousIcon}
            alt="previousIcon"
            className="prev-btn img-fluid"
            onClick={() => handlePrevious(currentIndex)}
          />

          <img src={modalImg} alt="Preview" className="model-image img-fluid" />
          {/* <button onClick={() => handleNext(currentIndex)}>Next</button> */}
          <img
            src={nextIcon}
            alt="nextIcon"
            className="next-btn img-fluid"
            onClick={() => handleNext(currentIndex)}
          />

          <div className="res-mobil-btns d-flex align-items-center justify-content-center pt-2">
            <img
              style={{ height: "30px", width: "30px" }}
              src={previousIcon}
              alt="previousIcon"
              onClick={() => handlePrevious(currentIndex)}
            />
            <img
              style={{ height: "30px", width: "30px" }}
              src={nextIcon}
              alt="nextIcon"
              className="ms-3"
              onClick={() => handleNext(currentIndex)}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Portfolio;
