import React, { useEffect, useRef, useState } from "react";
import "./ExplainerVideo.css";
import gridImage from "../assets/images/explainerVideoImnage.png";
import highlightImage from "../assets/images/highlightBox.png";
import dummyVideo from "../assets/videos/video.mp4";
import playIcon from "../assets/images/play.svg";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const ExplainerVideo = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const points = [
    {
      bulletsPoints:
        "• If the path is beautiful, let us not ask where it leads",
    },
    {
      bulletsPoints: "• My religion is very simple. my religion is kindness",
    },
    {
      bulletsPoints: "• Each of us has within our power the ability to disrupt",
    },
  ];

  const handleNavigation = () => {
    navigate("/about-us/");
  };
  return (
    <div className="explainer-video">
      <div className="container py-5">
        <div className="row position-relative">
          <div className="col-md-6 d-flex flex-column justify-content-center px-lg-5 mb-5 mb-lg-0 mb-md-0">
            <div className="position-relative">
              <img
                className="highlight-box"
                src={highlightImage}
                alt="highlight-img"
              />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <video
                  ref={videoRef}
                  onClick={handlePlayPause}
                  className=" video-style"
                  // controls
                >
                  <source src={dummyVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div
                  className={`play-button ${isPlaying ? "hidden" : ""}`}
                  onClick={handlePlayPause}
                >
                  <img src={playIcon} alt="play-icon" />
                </div>
              </div>
            </div>
          </div>

          <div className="explainer-content col-md-6 d-flex flex-column justify-content-center">
            <h1
              className="text-white font-face-Montserrat-Bold"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              The thing about us is{" "}
              <span
                className="d-lg-block text-theme-light"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                we think big, huge
              </span>
            </h1>

            <div className="text-white">
              {points?.map((x, index) => {
                return (
                  <div key={index}>
                    <p
                      className="m-0 font-face-Montserrat-Regular"
                      data-aos="zoom-in-left"
                    >
                      {x.bulletsPoints}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="text-center text-md-start text-lg-start mt-3">
              <button
                className="global-light-btn font-face-Montserrat-Medium"
                onClick={handleNavigation}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExplainerVideo;
