import React, { useEffect } from "react";
import "./Testimonial.css";

import rightArrow from "../assets/images/testimonialIconRight.svg";
import leftArrow from "../assets/images/testimonialIconLeft.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";

const Testimonial = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  // <Slider {...settings}>
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    // autoplay: true,
    arrows: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="testimonial-main">
      <div className="container">
        <div className="text-center text-white">
          <h1 className="font-face-Montserrat-Bold" data-aos="zoom-in">
            <span className="text-theme-light">Happy</span> Clients
          </h1>
          <p
            className="font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="1900"
          >
            {props?.testimonialSubHeading}
          </p>
        </div>

        <div className="row pt-5 mx-auto">
          <Slider {...settings}>
            {props?.testimonialData?.map((x, index) => {
              return (
                <div key={index} className="col-md-6 px-lg-5 mb-5">
                  <div className="testimonial-inner px-2 px-lg-5 px-md-5 py-4 text-center position-relative">
                    <p className="text-light-white pt-4 font-face-Montserrat-Regular">
                      {x.description}
                    </p>

                    <div className="pt-4">
                      <h5 className="text-theme m-0 font-face-Montserrat-Medium">
                        {x.userName}
                      </h5>
                      <h5 className="text-white m-0 font-face-Montserrat-Medium">
                        {x.userRole}
                      </h5>
                    </div>

                    <img
                      className="testimonial-left"
                      alt="left-arrow"
                      src={leftArrow}
                    />

                    <img
                      className="testimonial-right"
                      alt="right-arrow"
                      src={rightArrow}
                    />

                    <div className="testimonial-profile">
                      <img
                        className="testimonial-user"
                        alt="arrow-icon"
                        src={x.icon}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
