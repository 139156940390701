import React, { useEffect } from "react";
import "./CustomBranding.css";
import { useLocation } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const CustomBranding = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const location = useLocation();
  return (
    <div className="custom-branding">
      <div
        className={
          location.pathname === "/web-design-and-development/"
            ? "container-fluid custom-no-margin position-relative py-5"
            : "container py-5"
        }
      >
        <div className="text-center text-white px-1 px-md-0 px-lg-0">
          {location.pathname === "/web-design-and-development/" ? (
            <h1
              className="font-face-Montserrat-Bold"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              <span className="text-theme-light">One Stop</span> Solution for
              All Your
              <span className="d-lg-block">
                Web Development & Design{" "}
                <span className="text-theme-light">Needs!</span>
              </span>
            </h1>
          ) : (
            <h1
              className="font-face-Montserrat-Bold"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              Our Design Speaks for
              <span className="text-theme-light"> Itself!</span>
            </h1>
          )}
          {location.pathname === "/web-design-and-development/" ? (
            <p
              className="font-face-Montserrat-Regular"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              {props?.webDesignDevelopmentSubHeading}
            </p>
          ) : (
            <p
              className="font-face-Montserrat-Regular"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              {props?.brandingDesignSubHeading}
            </p>
          )}
        </div>

        {props?.brandingData?.map((x, index) => {
          const isLastItem = index === props.brandingData.length - 1;
          const rowClass = isLastItem ? "" : "mb-5";
          let rowAlignClass = "";

          if (location.pathname === "/web-design-and-development/") {
            if (index % 2 === 0) {
              rowAlignClass = "justify-content-end";
            } else {
              rowAlignClass = "justify-content-start";
            }
          } else {
            rowAlignClass = "justify-content-center";
          }

          let textAlignmentClass = "";
          if (rowAlignClass === "justify-content-end") {
            textAlignmentClass = "text-startr";
          } else if (rowAlignClass === "justify-content-start") {
            textAlignmentClass = "text-end";
          } else {
            textAlignmentClass = index % 2 === 0 ? "text-start" : "text-end";
          }

          return (
            <div
              key={index}
              className={`row pt-3 ${rowAlignClass} ${rowClass}`}
            >
              <div
                className={
                  location.pathname === "/web-design-and-development/"
                    ? "col-lg-10 col-md-8 branding-inner"
                    : "col-lg-10 col-md-8 branding-inner"
                }
              >
                <img src={x.image} alt="img-1" className="img-fluid w-100" />
                <div
                  className={`pt-3 ${
                    location.pathname === "/web-design-and-development/"
                      ? "px-3 px-lg-0 px-md-0"
                      : ""
                  } ${textAlignmentClass}`}
                >
                  <h4
                    className={`branding-heading font-face-Montserrat-Bold ${
                      isLastItem && textAlignmentClass === "text-end"
                        ? "text-end"
                        : ""
                    }`}
                    data-aos="zoom-in"
                    data-aos-duration="1900"
                  >
                    {x.title}
                  </h4>
                  <p
                    className={`text-white m-0 font-face-Montserrat-Regular ${
                      isLastItem && textAlignmentClass === "text-end"
                        ? "text-end"
                        : ""
                    }`}
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    {x.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomBranding;
