import React, { useEffect } from "react";
import aboutImage from "../assets/images/aboutImage.png";
import "./AboutUs.css";
import CountUp from "react-countup";
import AntDesignIcon from "../assets/images/Indesign.svg";
import FigmaIcon from "../assets/images/Figma.svg";
import WordPressIcon from "../assets/images/wordPress.svg";
import ReactNaiveIcon from "../assets/images/reactnative.svg";
import BootstrapIcon from "../assets/images/bootstrap.svg";
import IlustratorIcon from "../assets/images/ilustrator.svg";
import NodejsIcon from "../assets/images/nodejs.svg";
import CssIcon from "../assets/images/css.svg";
import HtmlIcon from "../assets/images/html.svg";
import Marquee from "react-fast-marquee";
import { Typewriter } from "react-simple-typewriter";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const details = [
    {
      count: "100",
      suffix: "+",
      description: "Team members",
    },
    {
      count: "500",
      suffix: "+",
      description: "Successful Projects",
    },
    {
      count: "4",
      suffix: "+",
      description: "Years in Industry",
    },
  ];

  const frameWorksAssets = [
    {
      icon: AntDesignIcon,
      frameTitle: "InDesign",
    },
    {
      icon: FigmaIcon,
      frameTitle: "Figma",
    },
    {
      icon: WordPressIcon,
      frameTitle: "WordPress",
    },
    {
      icon: ReactNaiveIcon,
      frameTitle: "React Naive",
    },
    {
      icon: BootstrapIcon,
      frameTitle: "BootStrap",
    },
    {
      icon: IlustratorIcon,
      frameTitle: "Ilustrator",
    },
    {
      icon: NodejsIcon,
      frameTitle: "Node Js",
    },
    {
      icon: CssIcon,
      frameTitle: "CSS3",
    },
    {
      icon: HtmlIcon,
      frameTitle: "HTML 5",
    },
  ];

  const frameWorksAssetsForMarqueeLeft = [
    {
      icon: AntDesignIcon,
      frameTitle: "InDesign",
    },
    {
      icon: FigmaIcon,
      frameTitle: "Figma",
    },
    {
      icon: WordPressIcon,
      frameTitle: "WordPress",
    },
    {
      icon: ReactNaiveIcon,
      frameTitle: "React Naive",
    },
  ];

  const frameWorksAssetsForMarqueeRight = [
    {
      icon: BootstrapIcon,
      frameTitle: "BootStrap",
    },
    {
      icon: IlustratorIcon,
      frameTitle: "Ilustrator",
    },
    {
      icon: NodejsIcon,
      frameTitle: "Node Js",
    },
    {
      icon: CssIcon,
      frameTitle: "CSS",
    },
    {
      icon: HtmlIcon,
      frameTitle: "HTML 5",
    },
  ];

  return (
    <div className="about-main">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center">
            {/* <h1 className="text-white text-center text-lg-start text-md-start font-face-Montserrat-Bold">
              <span className="text-theme-light">Who</span> We Are!
            </h1> */}
            <h1 className="text-white text-center text-lg-start text-md-start font-face-Montserrat-Bold">
              <Typewriter
                words={["Who We Are"]}
                loop={0}
                cursor
                cursorStyle="!"
                typeSpeed={70}
                deleteSpeed={90}
                delaySpeed={2000}
              />
            </h1>
            <p
              className="text-white text-center text-lg-start text-md-start font-face-Montserrat-Regular"
              data-aos="zoom-in-left"
            >
              We are pioneers, disruptors, creative mavens, digital maestros,
              <span className="d-lg-block">
                visionary strategists, trendsetters, & game changers.
              </span>
            </p>

            <div className="about-marque">
              <div className="social-buttons">
                <Marquee direction="left">
                  {frameWorksAssetsForMarqueeLeft?.map((frame, index) => {
                    return (
                      <div className="mx-1" key={index}>
                        <div className="frame-btn">
                          <img src={frame.icon} alt="ant-design" />
                          <small className="frame-title ms-3">
                            {frame.frameTitle}
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </Marquee>
              </div>

              <div className="social-buttons">
                <Marquee direction="right">
                  {frameWorksAssetsForMarqueeRight?.map((frame, index) => {
                    return (
                      <div className="mx-1" key={index}>
                        <div className="frame-btn">
                          <img src={frame.icon} alt="ant-design" />
                          <small className="frame-title ms-3">
                            {frame.frameTitle}
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </Marquee>
              </div>
            </div>

            <div className="about-lg">
              <div className="social-buttons">
                {frameWorksAssets?.map((frame, index) => {
                  return (
                    <div key={index} className="frame-btn">
                      <img src={frame.icon} alt="ant-design" />
                      <small className="frame-title ms-3">
                        {frame.frameTitle}
                      </small>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex align-items-center mt-5">
              {details?.map((x, index) => {
                return (
                  <div
                    className={index !== 0 ? "ms-3 ms-md-5 ms-lg-5" : ""}
                    key={index}
                  >
                    <h1 className="m-0 text-white font-face-Montserrat-Bold">
                      <CountUp end={x.count} suffix={x.suffix} />
                    </h1>
                    <p className="m-0 text-white font-face-Montserrat-Medium">
                      {x.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img
              className="img-fluid upndown"
              src={aboutImage}
              alt="about-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
